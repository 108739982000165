import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, inject, input } from '@angular/core';

import { ResourceItem } from '@ppg/core/enums';
import { RichTextComponent } from '@ppg/shared/rich-text';

import { ResourceService } from '../../services/resource/resource.service';

@Component({
  selector: 'ppg-resource-item',
  standalone: true,
  imports: [CommonModule, RichTextComponent],
  templateUrl: './resource-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResourceItemComponent {
  resourceService = inject(ResourceService);
  resourceKey = input.required<ResourceItem>();
  readonly resourceValue = computed(() => this.resourceService.getResourceByKey(this.resourceKey()));
}
