import { computed, inject, Pipe, PipeTransform } from '@angular/core';

import { ResourceItem } from '@ppg/core/enums';

import { ResourceService } from '../services/resource/resource.service';

@Pipe({
  name: 'resourceValue',
  standalone: true,
})
export class ResourceValuePipe implements PipeTransform {
  readonly #resourceService = inject(ResourceService);

  transform(
    resourceItemKey: ResourceItem,
    argsToReplace: { [key: string]: string } | undefined | null = undefined,
  ): string {
    const resourceValue = computed(() => {
      let resource = resourceItemKey ? this.#resourceService.getResourceByKey(resourceItemKey) : '';

      if (!argsToReplace || !resource) {
        return resource;
      }

      Object.keys(argsToReplace).forEach((key) => {
        const regex = new RegExp(`{\\s*${key}\\s*}`, 'gi');
        resource = resource.replace(regex, argsToReplace[key]);
      });

      return resource;
    });

    return resourceValue();
  }
}
