import { computed, inject, Injectable } from '@angular/core';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { filter, shareReplay, switchMap } from 'rxjs';

import { CurrentSiteState } from '@ppg/configuration';
import { ContentService } from '@ppg/core/content';
import { ResourceItem } from '@ppg/core/enums';
import { LanguagesService } from '@ppg/core/language';
import { Resource } from '@ppg/core/models';

@Injectable({ providedIn: 'root' })
export class ResourceService {
  private readonly contentService = inject(ContentService);
  private readonly currentSiteState = inject(CurrentSiteState);
  private readonly languagesService = inject(LanguagesService);
  private readonly currentSiteId = computed(() => this.currentSiteState.currentSite()?.id);
  private readonly siteParams = computed(() => ({
    siteId: this.currentSiteId(),
    language: this.languagesService.language(),
  }));
  // Fetch global resources only on language change
  readonly globalResources$ = toObservable(this.languagesService.language).pipe(
    filter((language): language is string => !!language),
    switchMap((language) => this.contentService.getResources(undefined, language)),
    shareReplay({ bufferSize: 1, refCount: true }),
  );
  // Fetch site resources either on site id or language change
  readonly siteResources$ = toObservable(this.siteParams).pipe(
    filter(
      (siteParams): siteParams is { siteId: number; language: string } => !!(siteParams.siteId && siteParams.language),
    ),
    switchMap(({ siteId, language }) => this.contentService.getResources(siteId, language)),
    shareReplay({ bufferSize: 1, refCount: true }),
  );

  private readonly globalResources = toSignal(this.globalResources$, { initialValue: [] });
  private readonly siteResources = toSignal(this.siteResources$, { initialValue: [] });

  readonly resources = computed(() => this.createResourcesMap([...this.globalResources(), ...this.siteResources()]));

  private createResourcesMap(resources: Resource[]): { [key in ResourceItem]?: string } {
    return resources.reduce<{ [key in ResourceItem]?: string }>(
      (items, { key, value }) => ({ ...items, [key]: value }),
      {},
    );
  }

  getResourceByKey(key: ResourceItem): string {
    return this.resources()[key] || '';
  }

  getResourceSignal(resourceItemKey: ResourceItem, argsToReplace: { [key: string]: string } | undefined = undefined) {
    return computed(() => {
      let resource = resourceItemKey ? this.getResourceByKey(resourceItemKey) : '';

      if (!resourceItemKey || !resource) {
        return resource;
      }

      if (argsToReplace) {
        Object.keys(resourceItemKey).forEach((key) => {
          const regex = new RegExp(`{\\s*${key}\\s*}`, 'gi');
          resource = resource.replace(regex, (argsToReplace as { [key: string]: string })[key]);
        });
      }

      return resource;
    });
  }
}
