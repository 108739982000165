import { NgTemplateOutlet } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, inject, input, Renderer2 } from '@angular/core';

import { RICHTEXT_CONTENT_CLASS_SELECTOR, TABLE_WRAPPER_CLASS_NAME } from './rich-text.consts';

@Component({
  selector: 'ppg-rich-text',
  standalone: true,
  imports: [NgTemplateOutlet],
  templateUrl: './rich-text.component.html',
  styleUrl: './rich-text.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RichTextComponent implements AfterViewInit {
  readonly content = input.required<string>();
  readonly fullWidth = input<boolean>(false);
  readonly isRoot = input<boolean>(false);

  readonly #element = inject(ElementRef);
  readonly #renderer = inject(Renderer2);

  ngAfterViewInit() {
    this.wrapTables();
  }

  wrapTables(): void {
    const richTextComponent = this.#element.nativeElement.querySelector(RICHTEXT_CONTENT_CLASS_SELECTOR);

    if (richTextComponent) {
      const tables = richTextComponent.querySelectorAll('table');

      if (tables.length === 0) {
        return;
      }

      tables.forEach((table: HTMLTableElement) => {
        const tableWrapper = this.#renderer.createElement('div');
        this.#renderer.addClass(tableWrapper, TABLE_WRAPPER_CLASS_NAME);
        this.#renderer.insertBefore(richTextComponent, tableWrapper, table);
        this.#renderer.appendChild(tableWrapper, table);
      });
    }
  }
}
