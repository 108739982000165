@if (isRoot()) {
  <div [class]="fullWidth() ? 'w-full justify-content-center' : 'w-full flex justify-content-center'">
    <div
      [class]="
        fullWidth()
          ? 'page-section page-section-x-padded general-section gap-4'
          : 'page-section page-section-x-padded general-section flex gap-4'
      ">
      <ng-container *ngTemplateOutlet="richTextContent"></ng-container>
    </div>
  </div>
} @else {
  <ng-container *ngTemplateOutlet="richTextContent"></ng-container>
}

<ng-template #richTextContent>
  <div class="richtext-content" [innerHtml]="content()"></div>
</ng-template>
